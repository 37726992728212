<template>
<div>
    <!-- 手机端展示 -->
    <div class="contain-page">
        <!-- info -->
        <div class="app-info">
            <div class="app-logo">
                <img src="@/assets/logo.png">
            </div>
            <div class="app-info-rig">
                <strong>有得钻</strong>
                <p>应用大小：30M</p>
                <div class="clr">
                    <a class="arouse"><b>?</b>安全认证</a>
                    <a class="btn btn-mini step2 blue" @click="onDownload">免费安装</a>
                </div>
            </div>
        </div>
        <!-- 评价 -->
        <div class="app-show">
            <div class="app-score">
                <strong>4.9</strong><img src="static/picture/star.png" alt="">
                <p><span class="down-count">999,999</span>个评分</p>
            </div>
            <div class="app-age">
                <strong>4+</strong>
                <p>年龄</p>
            </div>
        </div>
        <div class="app-intro">
            <div class="app-intro-con" style="height: auto;">
                <p style="padding: 8px 8px 8px 8px; color: white; background-color: #e64141; border-radius: 5px;">
                    1、安卓手机下载完点击安装即可<br>
                    2、Iphone手机点击安装完返回桌面，如下载完成，则点击设置 &gt;&gt; 通用 &gt;&gt; 描述文件 &gt;&gt; 有得钻&gt;&gt;安装
                </p>
            </div>
        </div>
        <!-- 图片展示 -->
        <!-- intro -->
        <div class="app-intro">
            <h2 class="app-title">简介</h2>
            <div class="app-intro-con" style="height: auto;">
                <p>百货/便利店/信息查询/提供回收产品核销工具</p>
                <span style="display: none;">更多</span>
            </div>
        </div>
        <!-- 评分及评论 -->
        <div class="comment-box">
            <h2 class="app-title">
                评分及评论
            </h2>
            <div class="comment-con">
                <div class="comment-left">
                    <strong>4.9</strong>
                    <p>满分 5 分</p>
                </div>
                <div class="comment-right">
                    <ul class="comment-star-list">
                        <li>
                            <div class="comment-star">
                                <img src="static/picture/star.png" alt="">
                                <div></div>
                            </div>
                            <div class="comment-progress">
                                <div></div>
                            </div>
                        </li>
                        <li>
                            <div class="comment-star">
                                <img src="static/picture/star.png" alt="">
                                <div></div>
                            </div>
                            <div class="comment-progress">
                                <div></div>
                            </div>
                        </li>
                        <li>
                            <div class="comment-star">
                                <img src="static/picture/star.png" alt="">
                                <div></div>
                            </div>
                            <div class="comment-progress">
                                <div></div>
                            </div>
                        </li>
                        <li>
                            <div class="comment-star">
                                <img src="static/picture/star.png" alt="">
                                <div></div>
                            </div>
                            <div class="comment-progress">
                                <div></div>
                            </div>
                        </li>
                        <li>
                            <div class="comment-star">
                                <img src="static/picture/star.png" alt="">
                                <div></div>
                            </div>
                            <div class="comment-progress">
                                <div></div>
                            </div>
                        </li>
                    </ul>
                    <p><span class="down-count">9,999</span>个评分</p>
                </div>
            </div>
        </div>
        <!-- 版本号 -->
        <div class="app-intro">
            <h2 class="app-title">新功能</h2>
            <div class="app-intro-con" style="height: auto;">
                <p>版本 1.2.0#</p>
            </div>
        </div>
        <!-- 信息 -->
        <div class="information-box">
            <h2 class="app-title">信息</h2>
            <ul class="information-list">
                <li>
                    <span class="l">销售商</span>
                    <div class="r">高宝信息科技</div>
                </li>
                <li>
                    <span class="l">大小</span>
                    <div class="r">40M</div>
                </li>
                <li>
                    <span class="l">类别</span>
                    <div class="r blue-color">核销</div>
                </li>
                <li>
                    <span class="l">语言</span>
                    <div class="r">简体中文</div>
                </li>
                <li>
                    <span class="l">年龄分级</span>
                    <div class="r">限17岁以上</div>
                </li>
                <li>
                    <span class="l">价格</span>
                    <div class="r">免费</div>
                </li>
                <li>
                    <span class="l blue-color">隐私政策</span>
                    <div class="r"></div>
                </li>
            </ul>
        </div>
        <!-- 免责声明 -->
        <div class="disclaimer">
            免责声明：<br>
            本网站仅提供下载托管，App内容相关事项由开发者负责，与本网站无关。
        </div>
        <!-- 微信蒙版 -->
        <div class="mask" v-if="isWx">
            <img v-if="uaType == 'iphone'" src="@/assets/invite_ios.png" alt="">
            <img v-else src="@/assets/invite_android.png" alt="">
        </div>
        <!-- safari提示框 -->
        <div class="mask-box safari-tips" v-if="isSafariMaskShow">
            <div class="mask-bg"></div>
            <div class="mask-pop">
                <span class="mask-colsed"><img src="@/assets/colsed.png" alt=""></span>
                <img class="copy-url-img" src="@/assets/safari-tip.png" alt="">
                <div class="copy-url">
                    <input id="foo" type="text" :value="iphoneUrl">
                    <button id="copyBtn" data-clipboard-action="copy" :data-clipboard-text="iphoneUrl" data-clipboard-target="#foo" @click="onCopy">复制</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Clipboard from 'clipboard'
import {Toast} from 'vant';
export default {
    data() {
        return {
            iphoneUrl: process.env.VUE_APP_IOS_DOWNLOAD_URL,
			//iphoneUrl: "itms-services:///?action=download-manifest&url=https://image.yanhe.szwwhl.com/upload/package/ios.plist",
            androidUrl: process.env.VUE_APP_ANDROID_DOWNLOAD_URL,
            uaType: '',
            isWx: false,
            isSafari: false,
            isSafariMaskShow: false,
        }
    },
    created(){
       this.init();
    },
    methods:{
       init(){
            this.uaType = this.getUaType();
            let ua = navigator.userAgent.toLowerCase();
            if (/(MicroMessenger)/i.test(ua)) { 
                this.isWx = true;
            }
            if(this.uaType == 'iphone' && /(safari)/i.test(ua)){
                this.isSafari = true;
                //自动跳转AppStore
                window.location.href = this.iphoneUrl;
            }
            if((/(iPhone|iPad|iPod|iOS)/i.test(ua) && /\sQQ/i.test(ua)) || (/(Android)/i.test(ua) && /MQQBrowser/i.test(ua) && /\sQQ/i.test((ua).split('MQQBrowser')))){
                this.isSafariMaskShow = true
            }

            //微信浏览器自动跳转到微信小程序
            if(this.isWx){
                window.location.href = this.iphoneUrl;
            }
       }, 
       getUaType(){
            let ua = navigator.userAgent.toLowerCase();
            let iphoneos = /(iPhone|iPad|iPod|iOS)/i.test(ua);
            let android = /(Android|HarmonyOS)/i.test(ua) ;
            return iphoneos ? "iphone" : android ? 'android' : 'pc';
       },
       onDownload(){
            let url = this.getUaType() == 'iphone' ? this.iphoneUrl : this.androidUrl;
            window.location.href = url;
       },
       onCopy(){
            let clipboard = new Clipboard('#copyBtn');
            clipboard.on('success', e => {
                Toast({ message: "复制成功",position:'cente'});
                clipboard.destroy()
            })
            clipboard.on('error', e => {
                Toast({ message: "该浏览器不支持自动复制!",position:'cente'});
                clipboard.destroy()
            })
       }
    }
}
</script>
<style>
html,
body,
div,
p,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
body {
    font-family: -apple-system;
    font-size: 12px;
    color: #000;
    background: #fff;
}

body,
html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input {
    border: none;
    background: none;
    outline: 0;
}

a {
    text-decoration: none;
}

ul,
li {
    list-style: none;
}

strong,
b,
em {
    font-weight: normal;
    font-style: normal;
}

.btn {
    display: block;
    width: 100%;
    padding: 4px 15px;
    background: rgba(4, 119, 249, 1);
    border: rgba(4, 119, 249, 1) 1px solid;
    border-radius: 15px;
    text-align: center;
    color: #fff;
    font-size: 14px;
}

.btn i {
    width: .3rem;
    height: .3rem;
    text-indent: -99999px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: -.06rem .1rem 0 .1rem;
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid rgba(225, 225, 225, .2);
    border-right-color: #fff;
    overflow: hidden;
    animation: three-quarters-loader 700ms infinite cubic-bezier(0, 0, .75, .91);
}

.btn.grey {
    border-color: #cacaca;
    background: #cacaca;
}

.step3 em {
    display: none;
}

.download-loading {
    position: relative;
    background: #dbdde2;
    overflow: hidden;
    width: 100px !important;
}

.download-loading i {
    display: none;
}

.download-loading span {
    position: relative;
    z-index: 1;
}
.download-loading span b {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    width: 50px;
}
.download-loading em {
    display: block;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: rgba(4, 119, 249, 1);
}

@keyframes three-quarters-loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg)
    }
}

.btn-mini {
    display: inline-block;
    width: auto;
}

.clr:after {
    display: table;
    clear: both;
    overflow: hidden;
}

.clr {
    zoom: 1;
}
.blue-color {
    color: #0070c9 !important;
}
/* banner */
.contain-page {
    max-width: 750px;
    margin: 0 auto;
}

.app-banner {
    display: none;
}

.app-banner img {
    display: block;
    width: 100%;
    height: 4rem;
}

/* info */
.app-info {
    display: flex;
    padding: 20px 0;
    width: 87.5%;
    margin: 0 auto;
    background: #fff;
}

.app-logo {
    width: 28vw;
    margin-right: 10px;
}

.app-logo img {
    display: block;
    width: 100%;
    border-radius: 20px;
}

.app-info-rig {
    flex: 1;
}

.app-info-rig strong {
    display: block;
    margin-top: 6px;
    margin-left: 3.28358%;
    font-size: 20px;
    font-weight: bold;
}

.app-info-rig p {
    margin: .3em 0 0 3.28358%;
    font-size: 14px;
    color: #8A8A90;
}
.app-info-rig .clr {
    margin-top: 1.8em;
}
.arouse {
    float: right;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    color: rgba(6, 122, 254, 1);
}
.arouse b {
	display:inline-block;
	vertical-align:middle;
	width:20px;
	height:20px;
	line-height:20px;
	margin:-2px 5px 0 0;
	text-align:center;
	background:rgba(6, 122, 254, 1);
    color: #fff;
	border-radius:100%;
	
}
/* 评价 */
.app-show {
    display: flex;
    padding: 0 0 20px;
    width: 87.5%;
    margin: 0 auto;
    background: #fff;
    color: #8E8F92;
}

.app-score {
    flex: 1;
}

.app-score strong,
.app-age strong {
    font-size: 16px;
    font-weight: bold;
}

.app-score p,
.app-age p {
    color: #d8d8d8;
    font-size: 12px;
}

.app-score img {
    width: 80px;
    margin-left: 5px;
}

.app-age {
    text-align: right;
}


/* intro */
.app-intro,.comment-box,.information-box {
    margin: 0 auto;
    padding: 20px 0;
    width: 87.5%;
    border-top: 1px solid #e5e5e5;
}

.app-title {
    margin-bottom: .85em;
    font-size: 20px;
}

.app-intro-con {
    position: relative;
    line-height: 1.8;
    font-size: 14px;
    height: 5.4em;
    overflow: hidden;
}

.app-intro-con.open {
    height: auto;
}

.app-intro-con span {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    padding-left: 1em;
    background: #fff;
    color: #067AFE;
}


/* 评分及评论 */
.comment-con {
    display: flex;
}

.comment-left {
    flex: 1;
}

.comment-left strong {
    font-size: 60px;
    line-height: 43px;
    color: #4A4A4E;
    font-weight: bold;
}

.comment-left p {
    width: 91px;
    text-align: center;
    color: #7B7B7B;
    margin-top: 10px;
}

.comment-star-list li {
    line-height: 1;
}
.comment-right {
    width: 63.38308%;
}
.comment-right p {
    margin-top: 5px;
    color: #7B7B7B;
    text-align: right;
}

.comment-star,
.comment-progress {
    display: inline-block;
    vertical-align: middle;
}

.comment-star {
    position: relative;
    width: 46px;
    height: 7px;
}

.comment-star img {
    display: block;
    width: 100%;
    height: 100%;
}

.comment-star div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #fff;
}

.comment-progress {
    position: relative;
    width: calc(100% - 56px);
    height: 2px;
    background: #E9E9EC;
    border-radius: 2px;
}

.comment-progress div {
    position: absolute;
    width: 0;
    height: 2px;
    background: #4A4A4E;
    border-radius: 2px;
}

.comment-star-list li:nth-child(1) .comment-progress div {
    width: 90%;
}

.comment-star-list li:nth-child(2) .comment-progress div {
    width: 10%;
}

.comment-star-list li:nth-child(2) .comment-star div {
    width: 20%;
}

.comment-star-list li:nth-child(3) .comment-star div {
    width: 40%;
}

.comment-star-list li:nth-child(4) .comment-star div {
    width: 60%;
}

.comment-star-list li:nth-child(5) .comment-star div {
    width: 80%;
}

/* 信息 */


.information-list li {
    display: flex;
    line-height: 3.5;
    border-bottom: #F2F2F2 1px solid;
    ;
}

.information-list li .l {
    color: #737379;
}

.information-list li .r {
    flex: 1;
    text-align: right;
}
.information-list li .r p {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
    line-height: 1.2;
}
.information-list li:last-child {
    border: none;
}

/* 展开 */
.open-btn {
    float: right;
    font-size: .26rem;
    line-height: .48rem;
    color: #067AFE;
}

.hidden {
    display: none;
}

.mask {
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
}

.mask img {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
}

.disclaimer {
    padding: 10px;
    color: rgba(153, 153, 153, 1);
    background: rgba(249, 249, 249, 1);
}
/* 弹框流程 */
.mask-box {
    z-index: 2;
    position: relative;
}

.mask-colsed {
    z-index: 2;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
}

.mask-colsed img {
    display: block;
    width: 100%;
}

.mask-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .2)
}

.mask-pop {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 300px;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
}
/*  */
.copy-url-img {
    display: block;
    width: 100%;
}

.copy-url {
    position: relative;
    margin: 20px 30px;
    height: 36px;
    line-height: 36px;
    background: #F1F6F9;
    border-radius: 18px;
    overflow: hidden;
}

.copy-url input {
    padding-left: 20px;
    color: #9A9A99;
}

.copy-url button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(90deg, rgba(34, 125, 249, 1), rgba(0, 203, 250, 1));
    color: #fff;
    border-radius: 0 18px 18px 0;
}

/*  */
.file-info {
    display: block;
    margin: 30px 0 20px;
    font-size: 14px;
    color: #00B0F9;
    text-align: center;

}

.file-box {
    z-index: 2;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    width: 70%;
    max-width: 300px;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;

}

.file-box h3 {
    text-align: center;
    font-size: 16px;
    color: #3A3A3A;

}

.file-con {
    margin: 20px 0;
    font-size: 14px;
    color: #777;
}

.file-con strong {
    display: block;
    margin-top: 20px;
    color: #333;
}

.file-con p {
    margin-top: 8px;
}

.colsed-btn {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(32, 124, 249, 1), rgba(0, 205, 250, 1));
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    text-align: center;
}



/* 加载框 */
.loading-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .9)
}

.loading-box span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -.8rem 0 0 -.8rem;
    width: 1.6rem;
    height: 1.6rem;
    box-sizing: border-box;
    border-radius: 100%;
    border: .08rem solid rgba(22, 39, 65, .2);
    border-right-color: #2A9FF6;
    overflow: hidden;
    animation: three-quarters-loader 700ms infinite cubic-bezier(0, 0, .75, .91);
}
/* 电脑展示 */
.pc-box {
    text-align: center;
}
.pc-box .info {
    font-size: 16px;
    font-weight: bold;
}
.pc-logo {
    width: 160px;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px auto 0;
}
.pc-logo img {
    display: block;
    width: 100%;
}
.pc-box > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5em;
}
.pc-box .code {
    width: 231px;
    height: 231px;
}
/* 图片展示 */
.imgs-box {
    width: 87.5%;
    margin: 0 auto 20px;
}
.imgs-box .swiper-slide {
    display: inline-block;
    vertical-align: bottom;
    width: auto;
    margin: 0;
    margin-right: 3.3vw;
    padding: 0;
    padding-bottom: .75em;
    white-space: normal;
    font-size: 12px;  
}
.imgs-box .swiper-slide img {
    display: block;
    width: auto;
    height: auto;
    min-width: 52vw;
    max-width: 82vw;
    max-height: 65vh;
    border-radius: 10px;
}
</style>