var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"contain-page"},[_c('div',{staticClass:"app-info"},[_vm._m(0),_c('div',{staticClass:"app-info-rig"},[_c('strong',[_vm._v("有得钻")]),_c('p',[_vm._v("应用大小：30M")]),_c('div',{staticClass:"clr"},[_vm._m(1),_c('a',{staticClass:"btn btn-mini step2 blue",on:{"click":_vm.onDownload}},[_vm._v("免费安装")])])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),(_vm.isWx)?_c('div',{staticClass:"mask"},[(_vm.uaType == 'iphone')?_c('img',{attrs:{"src":require("@/assets/invite_ios.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/invite_android.png"),"alt":""}})]):_vm._e(),(_vm.isSafariMaskShow)?_c('div',{staticClass:"mask-box safari-tips"},[_c('div',{staticClass:"mask-bg"}),_c('div',{staticClass:"mask-pop"},[_vm._m(9),_c('img',{staticClass:"copy-url-img",attrs:{"src":require("@/assets/safari-tip.png"),"alt":""}}),_c('div',{staticClass:"copy-url"},[_c('input',{attrs:{"id":"foo","type":"text"},domProps:{"value":_vm.iphoneUrl}}),_c('button',{attrs:{"id":"copyBtn","data-clipboard-action":"copy","data-clipboard-text":_vm.iphoneUrl,"data-clipboard-target":"#foo"},on:{"click":_vm.onCopy}},[_vm._v("复制")])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-logo"},[_c('img',{attrs:{"src":require("@/assets/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"arouse"},[_c('b',[_vm._v("?")]),_vm._v("安全认证")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-show"},[_c('div',{staticClass:"app-score"},[_c('strong',[_vm._v("4.9")]),_c('img',{attrs:{"src":"static/picture/star.png","alt":""}}),_c('p',[_c('span',{staticClass:"down-count"},[_vm._v("999,999")]),_vm._v("个评分")])]),_c('div',{staticClass:"app-age"},[_c('strong',[_vm._v("4+")]),_c('p',[_vm._v("年龄")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-intro"},[_c('div',{staticClass:"app-intro-con",staticStyle:{"height":"auto"}},[_c('p',{staticStyle:{"padding":"8px 8px 8px 8px","color":"white","background-color":"#e64141","border-radius":"5px"}},[_vm._v(" 1、安卓手机下载完点击安装即可"),_c('br'),_vm._v(" 2、Iphone手机点击安装完返回桌面，如下载完成，则点击设置 >> 通用 >> 描述文件 >> 有得钻>>安装 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-intro"},[_c('h2',{staticClass:"app-title"},[_vm._v("简介")]),_c('div',{staticClass:"app-intro-con",staticStyle:{"height":"auto"}},[_c('p',[_vm._v("百货/便利店/信息查询/提供回收产品核销工具")]),_c('span',{staticStyle:{"display":"none"}},[_vm._v("更多")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comment-box"},[_c('h2',{staticClass:"app-title"},[_vm._v(" 评分及评论 ")]),_c('div',{staticClass:"comment-con"},[_c('div',{staticClass:"comment-left"},[_c('strong',[_vm._v("4.9")]),_c('p',[_vm._v("满分 5 分")])]),_c('div',{staticClass:"comment-right"},[_c('ul',{staticClass:"comment-star-list"},[_c('li',[_c('div',{staticClass:"comment-star"},[_c('img',{attrs:{"src":"static/picture/star.png","alt":""}}),_c('div')]),_c('div',{staticClass:"comment-progress"},[_c('div')])]),_c('li',[_c('div',{staticClass:"comment-star"},[_c('img',{attrs:{"src":"static/picture/star.png","alt":""}}),_c('div')]),_c('div',{staticClass:"comment-progress"},[_c('div')])]),_c('li',[_c('div',{staticClass:"comment-star"},[_c('img',{attrs:{"src":"static/picture/star.png","alt":""}}),_c('div')]),_c('div',{staticClass:"comment-progress"},[_c('div')])]),_c('li',[_c('div',{staticClass:"comment-star"},[_c('img',{attrs:{"src":"static/picture/star.png","alt":""}}),_c('div')]),_c('div',{staticClass:"comment-progress"},[_c('div')])]),_c('li',[_c('div',{staticClass:"comment-star"},[_c('img',{attrs:{"src":"static/picture/star.png","alt":""}}),_c('div')]),_c('div',{staticClass:"comment-progress"},[_c('div')])])]),_c('p',[_c('span',{staticClass:"down-count"},[_vm._v("9,999")]),_vm._v("个评分")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-intro"},[_c('h2',{staticClass:"app-title"},[_vm._v("新功能")]),_c('div',{staticClass:"app-intro-con",staticStyle:{"height":"auto"}},[_c('p',[_vm._v("版本 1.2.0#")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information-box"},[_c('h2',{staticClass:"app-title"},[_vm._v("信息")]),_c('ul',{staticClass:"information-list"},[_c('li',[_c('span',{staticClass:"l"},[_vm._v("销售商")]),_c('div',{staticClass:"r"},[_vm._v("高宝信息科技")])]),_c('li',[_c('span',{staticClass:"l"},[_vm._v("大小")]),_c('div',{staticClass:"r"},[_vm._v("40M")])]),_c('li',[_c('span',{staticClass:"l"},[_vm._v("类别")]),_c('div',{staticClass:"r blue-color"},[_vm._v("核销")])]),_c('li',[_c('span',{staticClass:"l"},[_vm._v("语言")]),_c('div',{staticClass:"r"},[_vm._v("简体中文")])]),_c('li',[_c('span',{staticClass:"l"},[_vm._v("年龄分级")]),_c('div',{staticClass:"r"},[_vm._v("限17岁以上")])]),_c('li',[_c('span',{staticClass:"l"},[_vm._v("价格")]),_c('div',{staticClass:"r"},[_vm._v("免费")])]),_c('li',[_c('span',{staticClass:"l blue-color"},[_vm._v("隐私政策")]),_c('div',{staticClass:"r"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"disclaimer"},[_vm._v(" 免责声明："),_c('br'),_vm._v(" 本网站仅提供下载托管，App内容相关事项由开发者负责，与本网站无关。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"mask-colsed"},[_c('img',{attrs:{"src":require("@/assets/colsed.png"),"alt":""}})])
}]

export { render, staticRenderFns }